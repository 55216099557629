.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &.default {
    background: #3d734a;
    border-radius: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background: #8a8a8a;
    color: #ffffff;
    height: 50px;
    border: none;
    padding: 15px;
    width: 100%;

    &:hover:not(:disabled) {
      filter: brightness(1.1);
    }

    &:disabled {
      background: rgba(138, 138, 138, 1);
      color: #333333;
      opacity: 0.35;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      width: 15px;
      height: 15px;
      border: 1.5px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: rotation 1s linear infinite;
    }
  }

  @-webkit-keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
