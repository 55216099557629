.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.satisfactionSurvey {
  background: #ffffff;
  box-shadow: 0px 5px 35px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 45px auto;
  width: 450px;
  max-width: 100%;
  font-family: "Rubik", sans-serif;

  @media screen and (max-width: 991px) {
    margin: auto;
    box-shadow: none;
  }

  .storeLogo {
    margin-right: 20px;
    width: 80px;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .storeName {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 5px;
  }

  .orderNumber {
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.55);
  }

  .appreciate {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
  }

  .sublabel {
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .categories {
    .category {
      padding: 15px 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .stars {
        display: flex;

        .star {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 10px;
          }

          &.good {
            background: rgba(255, 199, 65, 0.2);
            border: 1px solid transparent;
          }

          &.bad {
            background: rgba(255, 80, 45, 0.2);
            border: 1px solid transparent;
          }

          i {
            color: #bdbdbd;
            font-size: 12px;
          }
        }
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .tag {
      padding: 10px 15px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 15px;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.selected {
        background: #000;
        color: #ffffff;
      }
    }
  }

  .success {
    display: flex;
    flex-direction: column;

    .storeLogo {
      margin-left: 20px;
      margin-right: 20px;
      width: 60px;
      height: 60px;

      img {
        width: 100%;
      }
    }

    .star {
      margin-top: 20px;
      width: 75px;
      height: 75px;

      img {
        width: 100%;
      }
    }

    .thankYou {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 15px;
    }
  }
}

.maxChar {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-top: 5px;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
}
