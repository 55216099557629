.inputContainer {
  position: relative;

  input,
  textarea {
    border: none;
    padding: 10px;
    min-height: 100px;
    background: #f2f2f2;
    border-radius: 10px;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.25);

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &:focus {
      background: #ededed;
      color: rgba(0, 0, 0, 0.55);
    }
  }
}
