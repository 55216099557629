*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body {
  max-width: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}


@media screen and (min-width: 992px) {
  body {
    min-height: 100vh;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}
